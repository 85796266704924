<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card class="primary--border" outlined>
                    <v-card-title class="title">
                        <span @click="enableAddBtnCount+=1">Modules</span>
                        <v-spacer></v-spacer>

                        <add-button
                                v-if="enableAddBtnCount>=10 && enableAddBtnCount<=13"
                                :permission="true"
                                @action="form.dialog = true">
                            New Module
                        </add-button>
                    </v-card-title>
                    <v-data-table
                            :headers="headers"
                            :items-per-page="pagination.rowsPerPage"
                            :hide-default-footer="!form.items.data.length"
                            :items="form.items.data"
                            :search="search"
                            :footer-props="rowsPerPageItems"
                            :options.sync="pagination"
                            :server-items-length="form.items.meta.total">
                        <template v-slot:item="{index, item}">
                            <tr>
                                <td>{{ index + form.items.meta.from }}</td>
                                <td class="text-xs-left">{{ item.name }}</td>
                                <td class="text-xs-left">-</td>
                                <td class="text-right">
                                    <view-button v-if="enableAddBtnCount>=10 && enableAddBtnCount<=13"
                                                 permission="permissions-read"
                                                 @click.native="viewPermissions(item.id)"/>
                                    <edit-button v-if="enableAddBtnCount>=10 && enableAddBtnCount<=13"
                                                 permission="modules-update" @agree="form.edit(item)"/>
                                    <delete-button v-if="enableAddBtnCount>=10 && enableAddBtnCount<=13"
                                                   permission="modules-delete" @agree="form.delete(item.id)"/>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>

                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="300px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Module Information</span>
                    </v-card-title>
                    <v-card-text class="pa-1">
                        <v-form ref="form" @submit.prevent="store"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="store"
                                lazy-validation>
                            <v-container grid-list-md>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <v-text-field outlined dense label="Module Name*" autocomplete="off" required
                                                      class="pa-0"
                                                      v-model="form.name" name="name"
                                                      :error-messages="form.errors.get('name')"/>
                                    </v-flex>
                                    <v-flex xs12>
                                        <v-select outlined dense v-model="form.service_id"
                                                  :items="services"
                                                  :error-messages="form.errors.get('service_id')"
                                                  label="Select Service"
                                                  class="pt-0"/>
                                    </v-flex>
                                    <v-flex xs12>
                                        <small>*indicates required field</small>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outlined @click="store">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({
                name: '',
                service_id: ''
            }, '/api/modules'),
            search: null,
            enableAddBtnCount: 0,
            pagination: {
                rowsPerPage: 50
            },
            rowsPerPageItems: {itemsPerPageOptions: [5, 10, 25, 50, 75]},
            headers: [
                {text: 'SN', align: 'center', value: 'id', width: 50},
                {text: 'Name', align: 'left', value: 'name'},
                {text: 'Service', align: 'left', value: 'service', sortable: false},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            services: [],
        }),
        watch: {
            // 'pagination': {
            //     handler () {
            //         this.get();
            //     },
            // },
            'pagination': function () {
                this.get();
            },
            'form.dialog': function () {
                this.getServices();
            },
            'form.name': function (value) {
                this.form.name = value.toUpperCase();
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' + Object.keys(json).map(key => {
                    if (typeof json[key] == 'object') {
                        let vals = [...json[key]];
                        if (vals.length) return encodeURIComponent(key) + '=' + encodeURIComponent(vals.join(','));
                        else return null;
                    }
                    return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                }).join('&');
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            store() {
                this.form.store();
            },

            getServices() {
                if (this.services.length == 0) {
                    this.$rest.get('/api/service').then(({data}) => {
                        this.services = data.data.map(item => {
                            return {text: item.name, value: item.id};
                        })
                    })
                }
            },

            viewPermissions(id) {
                this.$router.push({
                    name: 'module-permission',
                    params: {
                        moduleId: id
                    }
                })
            }

        }
    }
</script>
<style lang="scss" scoped></style>